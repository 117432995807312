import React from "react";
import Layout from "../components/layout";
import useIncidents from "../hooks/useIncidents";
import useLocale from "../hooks/useLocale";
import DataFilters from "../components/data/filters";
import { mapFilters } from "../style/theme";
import SEO from "../components/seo";
import Map from "../components/map";

export default function MapPage() {
  const { incidents, setFilters, filters } = useIncidents();
  const setCurrentPage = () => null;
  const { isAra } = useLocale();

  return (
    <Layout>
      <SEO metadata={{ title: "Data" }} />
      <div>
        <div css={{ display: "flex" }}>
          <DataFilters
            setFilters={setFilters}
            setCurrentPage={setCurrentPage}
            filters={filters}
            style={mapFilters}
          />
          <div css={{ width: "100%" }}>
            <Map
              incidents={incidents}
              viewport={{
                lat: 35,
                lng: 38,
                zoom: 7,
              }}
            />
          </div>
        </div>
      </div>
    </Layout>
  );
}
